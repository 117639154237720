import axios from 'axios';
import constant from './constant';
import { hashHistory } from "react-router";

function post(data, url) {
  let token = localStorage.getItem("token");
  if (token) {
    return new Promise(function (resolve, reject) {
      axios.post(constant.host + url, data, {headers: {"Content-Type": "application/x-www-form-urlencoded", "token": token}}).then(res =>{
        if (res.data.base_resp.ret === -2) {
          localStorage.removeItem("token");
          hashHistory.push("/login");
        }
        resolve(res);
      })
    })
  } else {
    return new Promise(function (resolve, reject) {
      resolve({"data":{"base_resp":{"ret":0,"msg":""},"data":""}});
    })
  }
}

export default{
  post: post
}
