import {Component} from 'react';
import { NavBar, Cell, Tabs, Calendar, Flex, Divider, Empty, Loading } from 'react-vant';
import querystring from 'querystring';
import Footer from './footer';
import './base.css';
import './table.css';
import requests from './requests';


export default class Table extends Component {

  constructor() {
    super();
    this.state = {
      requesting: false,
      visible: false,
      startDate: '',
      endDate: '',
      items: [],
      tab: 0
    }
  }

  componentDidMount() {
    this.getData('', '', "api/v1/findTicketTypeReportTable");
  }

  getDate(date, isFull=true) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    if (isFull) {
      return [year, month, day].join('-');
    } else {
      return [month, day].join('-');
    }
  }

  handleConfirm(items) {
    let tab = this.state.tab;
    let url = this.getUrl(tab);
    this.getData(this.getDate(items[0]), this.getDate(items[1]), url);
    this.setState({
      visible: false,
      startDate: this.getDate(items[0], true),
      endDate: this.getDate(items[1], true)
    })
  }

  getData(startDate, endDate, url) {
    this.setState({
      requesting: true,
      items: []
    })
    let data = querystring.stringify({startDate: startDate, endDate: endDate});
    requests.post(data, url).then((res) => {
      if(res.data.base_resp.ret === 0) {
        this.setState({
          requesting: false,
          items: res.data.data
        })
      }
    });
  }

  handleClick() {
    this.setState({
      visible: !this.state.visible
    })
  }

  getUrl(tab) {
    let url = "";
    if (tab === 0) {
      url = "api/v1/findTicketTypeReportTable";
    } else if (tab === 1) {
      url = "api/v1/findHallReportTable";
    } else if (tab === 2) {
      url = "api/v1/findMovieReportTable";
    } else if (tab === 3) {
      url = "api/v1/findSaleTicketTypeReportTable";
    }
    return url;
  }

  handleTabChange(tab) {
    let url = this.getUrl(tab);
    this.setState({
      tab: tab
    })
    this.getData(this.state.startDate, this.state.endDate, url);
  }

  get_data() {
    if (this.state.items.length < 1 && !this.state.requesting) {
      return (
        <Empty description="暂无数据" />
      )
    }
    let reportticket = 0;
    let reportprice = 0;
    let settleticket = 0;
    let settleprice = 0;
    let backticket = 0;
    let backprice = 0;
    let allticket = 0;
    let allprice = 0;
    let items = this.state.items.map(function(item, index) {
      if (item.reportticket) {
        reportticket += item.reportticket;
      }
      if (item.reportprice) {
        reportprice += parseInt(item.reportprice);
      }
      if (item.settleticket) {
        settleticket += item.settleticket;
      }
      if (item.settleprice) {
        settleprice += parseInt(item.settleprice);
      }
      if (item.backticket) {
        backticket += item.backticket;
      }
      if (item.backprice) {
        backprice += parseInt(item.backprice);
      }
      if (item.allticket) {
        allticket += item.allticket;
      }
      if (item.allprice) {
        allprice += parseInt(item.allprice);
      }
      return (
        <Flex key={index} className="item" justify="center" align="center">
          <Flex.Item className="name" span={3}>{item.name}</Flex.Item>
          <Flex.Item span={5}>{item.reportticket === null ? 0 : item.reportticket}/{item.reportprice === null ? 0 : parseInt(item.reportprice)}</Flex.Item>
          <Flex.Item span={5}>{item.settleticket === null ? 0 : item.settleticket}/{item.settleprice === null ? 0 : parseInt(item.settleprice)}</Flex.Item>
          <Flex.Item span={5}>{item.backticket === null ? 0 : item.backticket}/{item.backprice === null ? 0 : parseInt(item.backprice)}</Flex.Item>
          <Flex.Item span={5}>{item.allticket === null ? 0 : item.allticket}/{item.allprice === null ? 0 : parseInt(item.allprice)}</Flex.Item>
        </Flex>
      )
    });
    return (
      <div className="items">
        {items}
        <Flex className="summation">
          <Flex.Item span={3}>合计</Flex.Item>
          <Flex.Item span={5}>{reportticket}/{reportprice}</Flex.Item>
          <Flex.Item span={5}>{settleticket}/{settleprice}</Flex.Item>
          <Flex.Item span={5}>{backticket}/{backprice}</Flex.Item>
          <Flex.Item span={5}>{allticket}/{allprice}</Flex.Item>
        </Flex>
      </div>
    )
  }

  render() {
    return (
      <div className="table">
        <NavBar title="报表" leftArrow={false} border={false}/>
        <Tabs active={0} onChange={this.handleTabChange.bind(this)}>
          <Tabs.TabPane key={0} title="票类">
            <div className="tabs">
              <div className="date">
                <Cell.Group border={false}>
                  <Cell title="选择日期" value={this.state.startDate && this.state.startDate + " > " + this.state.endDate} isLink={true} onClick={this.handleClick.bind(this)} />
                  <Calendar
                    minDate={new Date(2020, 12, 1)}
                    maxDate={new Date(2030, 12, 1)}
                    defaultValue={new Date()}
                    style={{ height: 500 }}
                    visible={this.state.visible}
                    allowSameDay={true}
                    type={"range"}
                    poppable={true}
                    onClose={this.handleClick.bind(this)}
                    onConfirm={(dates) => {
                      this.handleConfirm(dates);
                    }}
                  />
                </Cell.Group>
              </div>
              <div className="content">
                <Divider />
                <div className="tips">
                  注: 数量/金额 (默认显示当天数据)
                </div>
                <Flex className="title">
                  <Flex.Item span={4}>名称</Flex.Item>
                  <Flex.Item span={5}>上报</Flex.Item>
                  <Flex.Item span={5}>上报中</Flex.Item>
                  <Flex.Item span={5}>退票</Flex.Item>
                  <Flex.Item span={5}>合计</Flex.Item>
                </Flex>
                {this.state.requesting &&
                  <Loading>加载中...</Loading>
                }
                {this.get_data()}
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane key={1} title="影厅">
            <div className="tabs">
              <div className="date">
                <Cell.Group border={false}>
                  <Cell title="选择日期" value={this.state.startDate && this.state.startDate + " > " + this.state.endDate} isLink={true} onClick={this.handleClick.bind(this)} />
                  <Calendar
                    minDate={new Date(2020, 12, 1)}
                    maxDate={new Date(2030, 12, 1)}
                    defaultValue={new Date()}
                    style={{ height: 500 }}
                    visible={this.state.visible}
                    allowSameDay={true}
                    type={"range"}
                    poppable={true}
                    onClose={this.handleClick.bind(this)}
                    onConfirm={(dates) => {
                      this.handleConfirm(dates);
                    }}
                  />
                </Cell.Group>
              </div>
              <div className="content">
                <Divider />
                <div className="tips">
                  注: 数量/金额 (默认显示当天数据)
                </div>
                <Flex className="title">
                  <Flex.Item span={4}>名称</Flex.Item>
                  <Flex.Item span={5}>上报</Flex.Item>
                  <Flex.Item span={5}>上报中</Flex.Item>
                  <Flex.Item span={5}>退票</Flex.Item>
                  <Flex.Item span={5}>合计</Flex.Item>
                </Flex>
                {this.state.requesting &&
                  <Loading>加载中...</Loading>
                }
                {this.get_data()}
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane key={2} title="影片">
            <div className="tabs">
              <div className="date">
                <Cell.Group border={false}>
                  <Cell title="选择日期" value={this.state.startDate && this.state.startDate + " > " + this.state.endDate} isLink={true} onClick={this.handleClick.bind(this)} />
                  <Calendar
                    minDate={new Date(2020, 12, 1)}
                    maxDate={new Date(2030, 12, 1)}
                    defaultValue={new Date()}
                    style={{ height: 500 }}
                    visible={this.state.visible}
                    allowSameDay={true}
                    type={"range"}
                    poppable={true}
                    onClose={this.handleClick.bind(this)}
                    onConfirm={(dates) => {
                      this.handleConfirm(dates);
                    }}
                  />
                </Cell.Group>
              </div>
              <div className="content">
                <Divider />
                <div className="tips">
                  注: 数量/金额 (默认显示当天数据)
                </div>
                <Flex className="title">
                  <Flex.Item span={4}>名称</Flex.Item>
                  <Flex.Item span={5}>上报</Flex.Item>
                  <Flex.Item span={5}>上报中</Flex.Item>
                  <Flex.Item span={5}>退票</Flex.Item>
                  <Flex.Item span={5}>合计</Flex.Item>
                </Flex>
                {this.state.requesting &&
                  <Loading>加载中...</Loading>
                }
                {this.get_data()}
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane key={3} title="销售">
            <div className="tabs">
              <div className="date">
                <Cell.Group border={false}>
                  <Cell title="选择日期" value={this.state.startDate && this.state.startDate + " > " + this.state.endDate} isLink={true} onClick={this.handleClick.bind(this)} />
                  <Calendar
                      minDate={new Date(2020, 12, 1)}
                      maxDate={new Date(2030, 12, 1)}
                      defaultValue={new Date()}
                      style={{ height: 500 }}
                      visible={this.state.visible}
                      allowSameDay={true}
                      type={"range"}
                      poppable={true}
                      onClose={this.handleClick.bind(this)}
                      onConfirm={(dates) => {
                        this.handleConfirm(dates);
                      }}
                  />
                </Cell.Group>
              </div>
              <div className="content">
                <Divider />
                <div className="tips">
                  注: 数量/金额 (默认显示当天数据)
                </div>
                <Flex className="title">
                  <Flex.Item span={4}>名称</Flex.Item>
                  <Flex.Item span={5}>上报</Flex.Item>
                  <Flex.Item span={5}>上报中</Flex.Item>
                  <Flex.Item span={5}>退票</Flex.Item>
                  <Flex.Item span={5}>合计</Flex.Item>
                </Flex>
                {this.state.requesting &&
                <Loading>加载中...</Loading>
                }
                {this.get_data()}
              </div>
            </div>
          </Tabs.TabPane>
        </Tabs>
        <Footer index={4}/>
      </div>
    );
  }
}
