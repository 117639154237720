import CryptoJS from "crypto-js";
const key = "1W" + ".4" + "5a" + "78e" + "0x2" + "3c" + "+6";
const iv = "12" + "zP" + "H2" + "9z" + "io9" + "p21" + "+w";

function getAesString(data, key, iv) {
  key = CryptoJS.enc.Utf8.parse(key);
  iv = CryptoJS.enc.Utf8.parse(iv);
  let encrypted = CryptoJS.AES.encrypt(data,key,
    {
      iv:iv,
      mode:CryptoJS.mode.CBC,
      padding:CryptoJS.pad.Pkcs7
    });
  return encrypted.toString();
}

function getDAesString(encrypted, key, iv) {
  key = CryptoJS.enc.Utf8.parse(key);
  iv = CryptoJS.enc.Utf8.parse(iv);
  let decrypted = CryptoJS.AES.decrypt(encrypted,key,
    {
      iv:iv,
      mode:CryptoJS.mode.CBC,
      padding:CryptoJS.pad.Pkcs7
    });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

function encrypte(data) {
  let encrypted = getAesString(data,key,iv);
  let encrypted1 = CryptoJS.enc.Utf8.parse(encrypted);
  return encrypted;
}

function decrypte(data) {
  let decryptedStr = getDAesString(data,key,iv);
  return decryptedStr;
}

export default {
  encrypte: encrypte,
  decrypte: decrypte
}
