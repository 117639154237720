import {Component} from 'react';
import { NavBar, Cell, Grid, Dialog } from 'react-vant';
import { Close, ShieldO, DeleteO, SettingO, VipCardO, Points } from '@react-vant/icons';
import querystring from 'querystring';
import { hashHistory } from "react-router";
import Footer from './footer';
import Overview from './overview';
import requests from './requests';
import './base.css';
import './home.css';
import axios from "axios";
import constant from "./constant";


export default class Home extends Component {

  constructor() {
    super();
    this.state = {
      expireTime: '',
      name: '',
      visible: false,
    }
  }

  componentDidMount() {
    let data = querystring.stringify();
    requests.post(data, "api/v1/expire").then((res) => {
      if(res.data.base_resp.ret === 0) {
        this.setState({
          expireTime: res.data.data
        })
      }
    });

    axios.post(constant.host + "api/v1/user/info").then((res) => {
      if (res.data.base_resp.ret === 0) {
        this.setState({
          name: res.data.data
        });
      }
    });
  }

  handleClick(url) {
    hashHistory.push(url);
  }

  handleDialogClick() {
    Dialog.confirm({
      message:
        '关闭后，软件所有功能将停止使用，如需重新使用，请重启此软件。',
    }).then(() => {
      let data = querystring.stringify();
      requests.post(data, "api/v1/stop").then((res) => {
        if(res.data.base_resp.ret === 0) {
          localStorage.removeItem("token");
          hashHistory.push("/login");
        }
      });
    }).catch(() => {
    });
  }

  render() {
    return (
      <div className="home">
        <NavBar title={this.state.name} leftArrow={false}/>
        <Overview />
        <div className="remain">
          <Cell.Group border={false}>
            <Cell title="剩余天数" value={this.state.expireTime + "天"} />
          </Cell.Group>
        </div>
        <div className="track">
          <Grid columnNum={2} border={false} gutter={10}>
            <Grid.Item className="track-item-1" icon={<Close />} onClick={this.handleDialogClick.bind(this)}  text="应急关闭" />
            <Grid.Item className="track-item-2" icon={<ShieldO />} onClick={this.handleClick.bind(this, "/changePassword")} text="修改密码" />
            <Grid.Item className="track-item-3" icon={<DeleteO />} onClick={this.handleClick.bind(this, "/clear")} text="清除数据" />
            <Grid.Item className="track-item-4" icon={<SettingO />} onClick={this.handleClick.bind(this, "/setting")} text="设置" />
            <Grid.Item className="track-item-5" icon={<VipCardO />} onClick={this.handleClick.bind(this, "/member")} text="会员查询" />
            <Grid.Item className="track-item-6" icon={<Points />} onClick={this.handleClick.bind(this, "/ticketTable")} text="数据导出" />
          </Grid>
        </div>
        <Footer index={0}/>
      </div>
    );
  }
}
