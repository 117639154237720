import {Component} from 'react';
import { Calendar, Cell, Grid } from 'react-vant';
import querystring from 'querystring';
import './overview.css';
import requests from './requests';


export default class Overview extends Component {

  constructor() {
    super();
    this.state = {
      visible: false,
      date: "",
      reportPrice: 0,
      reportTicket: 0,
      refundPrice: 0,
      refundTicket: 0,
      totalPrice: 0,
      totalTicket: 0,
      remainPrice: 0,
      remainTicket: 0
    }
  }

  componentDidMount() {
    let date = this.getDate(new Date());
    this.setState({
      date: date
    })
    this.getData(date);
  }

  getData(date) {
    let data = querystring.stringify({date: date});
    requests.post(data, "api/v1/findTicketAndPrice1").then((res) => {
      if(res.data.base_resp.ret === 0) {
        this.setState({
          reportPrice: res.data.data.REPORTPRICE,
          reportTicket: res.data.data.REPORTTICKET
        })
      }
    });
    requests.post(data, "api/v1/findTicketAndPrice2").then((res) => {
      if(res.data.base_resp.ret === 0) {
        this.setState({
          remainPrice: res.data.data.SETTLEPRICE,
          remainTicket: res.data.data.SETTLETICKET
        })
      }
    });
    requests.post(data, "api/v1/findTicketAndPrice3").then((res) => {
      if(res.data.base_resp.ret === 0) {
        this.setState({
          totalPrice: res.data.data.ALLPRICE,
          totalTicket: res.data.data.ALLTICKET
        })
      }
    });
    requests.post(data, "api/v1/findTicketAndPrice4").then((res) => {
      if(res.data.base_resp.ret === 0) {
        this.setState({
          refundPrice: res.data.data.BACKPRICE,
          refundTicket: res.data.data.BACKTICKET
        })
      }
    });
  }

  getDate(date) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  handleConfirm(data) {
    let date = this.getDate(new Date(data));
    this.setState({
      visible: false,
      date: date
    })
    this.getData(date);
  }

  handleClick() {
    this.setState({
      visible: !this.state.visible
    })
  }

  render() {
    return (
      <div className="overview">
        <Cell.Group border={false}>
          <Grid columnNum={1} border={false}>
            <Grid.Item className="date" onClick={this.handleClick.bind(this)} text={this.state.date} />
          </Grid>
          <Cell title="上报情况" value={this.state.reportTicket + " / " + this.state.reportPrice} />
          <Cell title="留存情况" value={this.state.remainTicket + " / " + this.state.remainPrice} />
          <Cell title="实际情况" value={this.state.totalTicket + " / " + this.state.totalPrice} />
          <Cell title="退票情况" value={this.state.refundTicket + " / " + this.state.refundPrice} />
        </Cell.Group>
        <Calendar
          minDate={new Date(2020, 12, 1)}
          maxDate={new Date(2030, 12, 1)}
          defaultValue={new Date()}
          style={{ height: 500 }}
          visible={this.state.visible}
          poppable={true}
          onClose={this.handleClick.bind(this)}
          onConfirm={(date) => {
            this.handleConfirm(date);
          }}
        />
      </div>
    );
  }
}
