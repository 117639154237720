import {Component} from 'react';
import { Field, Button, NavBar, Flex, Divider, Empty } from 'react-vant';
import querystring from 'querystring';
import Footer from './footer';
import './base.css';
import './member.css';
import requests from './requests';


export default class Member extends Component {

  constructor() {
    super();
    this.state = {
      phone: "",
      items: [],
    }
  }

  get_date(timestamp) {
    let date = new Date(timestamp)
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
  }

  getData() {
    let phone = this.state.phone;
    if (!phone) {
      this.setState({
        items: []
      })
      return;
    }
    let url = "api/v1/member/card/tickets";
    this.setState({
      items: []
    })
    let data = querystring.stringify({phone: phone});
    requests.post(data, url).then((res) => {
      if(res.data.base_resp.ret === 0) {
        let items = res.data.data;
        if (items.length > 0) {
          items.sort((a, b) => {
            return a.CBY_RCRE_DATE - b.CBY_RCRE_DATE;
          })
        }
        this.setState({
          items: items
        })
      }
    });
  }

  handleChange(phone) {
    this.setState({
      phone: phone
    })
  }

  get_data() {
    let _this = this;
    if (this.state.items.length < 1) {
      return (
        <Empty description="暂无数据" />
      )
    }
    let tickets = 0;
    let items = this.state.items.map(function(item, index) {
      if (item.CBY_TOTAL_SEATS_BOOKED) {
        tickets += item.CBY_TOTAL_SEATS_BOOKED;
      }
      return (
        <Flex key={index} className="item" justify="center" align="center">
          <Flex.Item className="name" span={6}>{item.CBY_FILM_TITLE}</Flex.Item>
          <Flex.Item span={6}>{_this.get_date(item.CBY_RCRE_DATE)}</Flex.Item>
          <Flex.Item span={3}>{item.CBY_SHOW_TIME}</Flex.Item>
          <Flex.Item span={3}>{item.CBY_TOTAL_AMT_PAYABLE_DE}</Flex.Item>
          <Flex.Item span={3}>{item.CBY_AFT_BALANCE_DE}</Flex.Item>
          <Flex.Item span={3}>{item.CBY_TOTAL_SEATS_BOOKED}</Flex.Item>
        </Flex>
      )
    });
    return (
      <div className="items">
        {items}
        <Flex className="summation">
          <Flex.Item span={6}>总票数</Flex.Item>
          <Flex.Item span={6}></Flex.Item>
          <Flex.Item span={3}></Flex.Item>
          <Flex.Item span={3}></Flex.Item>
          <Flex.Item span={3}></Flex.Item>
          <Flex.Item span={3}>{tickets}</Flex.Item>
        </Flex>
      </div>
    )
  }

  render() {
    return (
      <div className="member">
        <NavBar title="会员查询" leftArrow={false} border={false}/>
          <div className="tabs">
            <div className="search">
              <Field onChange={this.handleChange.bind(this)} label="请输入手机号" placeholder="会员手机号"/>
              <Button className="search-button" onClick={this.getData.bind(this)}>查询</Button>
            </div>

            <div className="content">
              <Divider />
              <Flex className="title">
                <Flex.Item span={6}>名称</Flex.Item>
                <Flex.Item span={6}>时间</Flex.Item>
                <Flex.Item span={3}>场次</Flex.Item>
                <Flex.Item span={3}>金额</Flex.Item>
                <Flex.Item span={3}>余额</Flex.Item>
                <Flex.Item span={3}>票数</Flex.Item>
              </Flex>
              {this.get_data()}
            </div>
          </div>
        <Footer pathname="member" />
      </div>
    );
  }
}
