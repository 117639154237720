import {Component} from 'react';
import { Button, NavBar, Cell, Calendar, Dialog, Toast, Divider } from 'react-vant';
import querystring from 'querystring';
import Footer from './footer';
import './base.css';
import './control.css';
import requests from './requests';


export default class Control extends Component {

  constructor() {
    super();
    this.state = {
      types: "",
      rate: '',
      interval: '',
      settleTime: '',
      isRun: null,
      movies: "",
      visible: false,
      dates: []
    }
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    let data = querystring.stringify();
    requests.post(data, "api/v1/findTicketRuleInfo").then((res) => {
      if(res.data.base_resp.ret === 0) {
        this.setState({
          types: res.data.data.types.replaceAll(",", '、'),
          rate: res.data.data.rate,
          interval: res.data.data.interval,
          settleTime: res.data.data.daySettleTime,
          movies: res.data.data.movies.replaceAll(",", '、'),
          isRun: res.data.data.isRun
        })
      }
    });
  }

  handleSubmit(isRun) {
    let message = '';
    if (isRun) {
      message = "确认开启？";
    } else {
      message = "确认关闭？";
    }
    Dialog.confirm({
      message: message,
    }).then(() => {
      let data = querystring.stringify({isRun: isRun});
      requests.post(data, "api/v1/status").then((res) => {
        if(res.data.base_resp.ret === 0) {
          if (isRun) {
            Toast.success('开启完成');
          } else {
            Toast.success('关闭完成');
          }
          this.setState({
            isRun: isRun
          })
        }
      });
    }).catch(() => {
    });
  }

  handleSettleSubmit() {
    if (this.state.dates.length > 0) {
      let data = querystring.stringify({dates: this.state.dates.join(',')});
      requests.post(data, "api/v1/daySettleTicketData").then((res) => {
        if(res.data.base_resp.ret === 0) {
          Toast.success('日结完成');
        }
      });
    } else {
      Toast.info('请选择一个日期');
    }
  }

  getDate(date) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  handleClick() {
    this.setState({
      visible: !this.state.visible
    })
  }

  handleConfirmMulti(dates) {
    let _this = this;
    let data = [];
    dates.forEach(function (date) {
      data.push(_this.getDate(date));
    });
    this.setState({
      visible: false,
      dates: data
    })
  }

  render() {
    return (
      <div className="control">
        <NavBar title="控制" leftArrow={false}/>
        <div className="block">
          <div className="title">拦截票类:</div>
          <div className="content">{this.state.types}</div>
          <div className="item"><span>拦截比例: </span>{this.state.rate + "%"}</div>
          <div className="item"><span>结场时间: </span>{"每场" + this.state.interval + "分钟后结算"}</div>
          <div className="item"><span>日结时间: </span>{this.state.settleTime}</div>
          <div className="filter"><span>不拦截的影片:</span></div>
          <div className="content">{this.state.movies}</div>
        </div>
        <div className="submit">
          <div className="item">
            {this.state.isRun === 0 ?
              <Button className="button" type="primary" round={true} block={true} onClick={this.handleSubmit.bind(this, 1)} >
                开始
              </Button>
              :
              <Button className="button" type="danger" round={true} block={true} onClick={this.handleSubmit.bind(this, 0)} >
                关闭
              </Button>
            }
          </div>
          <Divider>手动日结</Divider>
        </div>
        <div className="block">
          <Cell title="选择日结日期" value={this.state.dates.length > 0 && "选择了" + this.state.dates.length + "个日期"} isLink={true} onClick={this.handleClick.bind(this)} />
          <Calendar
              minDate={new Date(2020, 12, 1)}
              maxDate={new Date(2030, 12, 1)}
              defaultValue={new Date()}
              style={{ height: 500 }}
              visible={this.state.visible}
              type={"multiple"}
              poppable={true}
              onClose={this.handleClick.bind(this)}
              onConfirm={(dates) => {
                this.handleConfirmMulti(dates);
              }}
            />
        </div>
        <div className="submit">
          <div className="item">
            <Button className="button" type="default" round={true} block={true} onClick={this.handleSettleSubmit.bind(this)} >
              手动日结
            </Button>
          </div>
        </div>
        <Footer index={1}/>
      </div>
    );
  }
}
