import {Component} from 'react';
import { Form, Field, Button, NavBar, Cell, Popup, Picker, Checkbox, CheckboxGroup, DatetimePicker, Toast } from 'react-vant';
import querystring from 'querystring';
import Footer from './footer';
import './base.css';
import './setting.css';
import requests from './requests';


export default class Setting extends Component {

  constructor() {
    super();
    this.state = {
      types: [],
      tempTypes: [],
      choseTypes: [],
      rate: '',
      interval: '',
      settleTime: '',
      movies: [],
      tempMovies: [],
      choseMovies: [],
      movieCodes: []
    }
  }

  componentDidMount() {
    let data = querystring.stringify();
    requests.post(data, "api/v1/findTicketType").then((res) => {
      if(res.data.base_resp.ret === 0) {
        this.setState({
          types: res.data.data
        })
      }
    });

    requests.post(data, "api/v1/findTicketRuleInfo").then((res) => {
      if(res.data.base_resp.ret === 0) {
        this.setState({
          choseTypes: res.data.data.types,
          choseMovies: res.data.data.movies,
          movieCodes: res.data.data.movieCodes,
          rate: res.data.data.rate,
          interval: res.data.data.interval,
          settleTime: res.data.data.daySettleTime
        });
      }
    });

    requests.post(data, "api/v1/findMovie").then((res) => {
      if(res.data.base_resp.ret === 0) {
        this.setState({
          movies: res.data.data
        });
      }
    });
  }

  handleClick(item) {
    this.setState({
      [item]: true
    });
  }

  handleSubmit() {
    let types = '';
    let movies = '';
    let movieCodes = '';
    try {
      types = this.state.choseTypes.join(',');
    } catch (error) {
      types = this.state.choseTypes;
    }
    try {
      movies = this.state.choseMovies.join(',');
    } catch (error) {
      movies = this.state.choseMovies;
    }
    try {
      movieCodes = this.state.movieCodes.join(',');
    } catch (error) {
      movieCodes = this.state.movieCodes;
    }
    let rate = this.state.rate;
    let interval = this.state.interval;
    let settleTime = this.state.settleTime;
    let data = querystring.stringify({types: types, rate: rate, interval: interval, settleTime: settleTime, movies: movies, movieCodes: movieCodes});
    requests.post(data, "api/v1/setting").then((res) => {
      if(res.data.base_resp.ret === 0) {
        Toast.success('设置成功');
      }
    });
  }

  handleTypesSubmit() {
    this.setState({
      showTypes: false,
      choseTypes: this.state.tempTypes,
      tempTypes: []
    });
  }

  handleTypesClose() {
    this.setState({
      showTypes: false
    });
  }

  handleGroupChange(names) {
    this.setState({
      tempTypes: names
    })
  }

  handleMoviesSubmit() {
    let choseMovies = [];
    let movieCodes = [];
    let tempMovies = this.state.tempMovies;
    tempMovies.forEach((item) => {
      choseMovies.push(item.FILMNAME);
      movieCodes.push(item.FILMCODE);
    })
    this.setState({
      showMovies: false,
      choseMovies: choseMovies,
      movieCodes: movieCodes,
      tempMovies: []
    });
  }

  handleMoviesClose() {
    this.setState({
      showMovies: false
    });
  }

  handleMovieGroupChange(names) {
    this.setState({
      tempMovies: names
    })
  }

  handleRateCancle() {
    this.setState({
      showRate: false
    });
  }

  handleRateConfirm(value) {
    this.setState({
      rate: value,
      showRate: false
    });
  }

  handleIntervalCancle() {
    this.setState({
      showInterval: false
    });
  }

  handleIntervalConfirm(value) {
    this.setState({
      interval: value,
      showInterval: false
    });
  }

  handleSettleCancle() {
    this.setState({
      showSettle: false
    });
  }

  handleSettleConfirm(value) {
    this.setState({
      settleTime: value,
      showSettle: false
    });
  }

  get_data() {
    let _this = this;
    let items = this.state.types.map(function(item, index) {
      return (
        <Cell key={index}>
          <Checkbox name={item}>{item}</Checkbox>
        </Cell>
      )
    });
    return (
      <CheckboxGroup defaultChecked={_this.state.choseTypes} onChange={_this.handleGroupChange.bind(_this)}>
        <Cell.Group border={false}>
          {items}
        </Cell.Group>
        <div className="types">
          <Button className="button first" type="default" onClick={_this.handleTypesClose.bind(_this)} >
            取消
          </Button>
          <Button className="button" type="primary" onClick={_this.handleTypesSubmit.bind(_this)} >
            确定
          </Button>
        </div>
      </CheckboxGroup>
    )
  }

  get_movies_data() {
    let _this = this;
    let items = this.state.movies.map(function(item, index) {
      return (
        <Cell key={index}>
          <Checkbox name={item}>{item.FILMNAME}</Checkbox>
        </Cell>
      )
    });
    return (
      <CheckboxGroup defaultChecked={_this.state.choseMovies} onChange={_this.handleMovieGroupChange.bind(_this)}>
        <Cell.Group border={false}>
          {items}
        </Cell.Group>
        <div className="types">
          <Button className="button first" type="default" onClick={_this.handleMoviesClose.bind(_this)} >
            取消
          </Button>
          <Button className="button" type="primary" onClick={_this.handleMoviesSubmit.bind(_this)} >
            确定
          </Button>
        </div>
      </CheckboxGroup>
    )
  }

  render() {
    return (
      <div className="setting">
        <NavBar title={this.state.showTypes ? "选择拦截票类" : "设置"} leftArrow={false}/>
        <div className={(this.state.showTypes || this.state.showMovies) ? "hide" : "active"}>
          <div className="tabs">
            <div className="before">
              <Form>
                <Cell.Group border={false}>
                  <Field className="input" label="拦截票类" placeholder="请选择" value={this.state.choseTypes} onClick={this.handleClick.bind(this, "showTypes")} isLink={true} readonly={true} />
                  <Field className="input" label="拦截比例" placeholder="请选择" value={this.state.rate && this.state.rate + "%"} onClick={this.handleClick.bind(this, "showRate")} isLink={true} readonly={true} />
                  <Field className="input" label="结场时间" placeholder="请选择" value={this.state.interval && this.state.interval + "分钟"} onClick={this.handleClick.bind(this, "showInterval")} isLink={true} readonly={true} />
                  <Field className="input" label="日结时间" placeholder="请选择" value={this.state.settleTime} onClick={this.handleClick.bind(this, "showSettle")} isLink={true} readonly={true} />
                  <Field className="input" label="不拦截的影片" placeholder="请选择" value={this.state.choseMovies} onClick={this.handleClick.bind(this, "showMovies")} isLink={true} readonly={true} />
                </Cell.Group>
              </Form>
            </div>
            <div>
              <Button className="button" type="primary" round={true} block={true} onClick={this.handleSubmit.bind(this)} >
                确定
              </Button>
            </div>
          </div>
          <Footer index={3}/>
          <Popup visible={this.state.showRate} position="bottom" round={true} title="选择拦截比例">
            <Picker columns={['10', '20', '30', '40', '50', '60', '70', '80', '90', '100']} onCancel={this.handleRateCancle.bind(this)} onConfirm={this.handleRateConfirm.bind(this)}>
            </Picker>
          </Popup>
          <Popup visible={this.state.showInterval} position="bottom" round={true} title="选择场结时间">
            <Picker columns={['10']} onCancel={this.handleIntervalCancle.bind(this)} onConfirm={this.handleIntervalConfirm.bind(this)}>
            </Picker>
          </Popup>
          <Popup visible={this.state.showSettle} position="bottom" round={true} title="选择日结时间">
            <DatetimePicker type="time" onCancel={this.handleSettleCancle.bind(this)} onConfirm={this.handleSettleConfirm.bind(this)}>
            </DatetimePicker>
          </Popup>
        </div>
        <div className={this.state.showTypes ? "active" : "hide"}>
          {this.get_data()}
        </div>
        <div className={this.state.showMovies ? "active" : "hide"}>
          {this.get_movies_data()}
        </div>
      </div>
    );
  }
}
