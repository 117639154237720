import {Component} from 'react';
import { Form, Field, Cell, Button, NavBar, Toast } from 'react-vant';
import querystring from 'querystring';
import { hashHistory } from "react-router";
import requests from './requests';
import aes from './aes';
import './base.css';
import './login.css';

export default class ChangePassword extends Component {

  constructor() {
    super();
    this.state = {
      oldPassword: "",
      newPassword: ""
    }
  }

  componentDidMount() {
    if (!localStorage.getItem("token")) {
      hashHistory.push("/login");
    }
  }

  handleChange(username, value) {
    this.setState({
      [username]: value
    });
  }

  handleSubmit() {
    let oldPassword = aes.encrypte(this.state.oldPassword);
    let newPassword = aes.encrypte(this.state.newPassword);
    let data = querystring.stringify({oldPassword: oldPassword, newPassword: newPassword});
    requests.post(data, "api/v1/changePassword").then((res) => {
      if (res.data.base_resp.ret === 0) {
        if (localStorage.getItem('username')) {
          localStorage.setItem('password', newPassword);
        }
        hashHistory.push("/");
      } else {
        Toast.info('旧密码错误');
      }
    });
  }

  handleLeftClick() {
  }


  render() {
    return (
      <div className="login">
        <NavBar title="修改密码" onClickLeft={hashHistory.goBack}/>
        <Form className="user">
          <Cell.Group border={false}>
            <Field className="input" type="password" label="旧密码" placeholder="旧密码" value={this.state.password} onChange={this.handleChange.bind(this, "oldPassword")} />
            <Field className="input" type="password" label="新密码" placeholder="新密码" value={this.state.password} onChange={this.handleChange.bind(this, "newPassword")} />
          </Cell.Group>
          <div>
            <Button className="button" type="primary" round={true} block={true} onClick={this.handleSubmit.bind(this)} >
              确定
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}
