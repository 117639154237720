import {Component} from 'react';
import { Tabbar, NumberKeyboard } from 'react-vant';
import { WapHomeO, DesktopO, UnderwayO, SettingO, BarChartO } from '@react-vant/icons';
import { hashHistory } from "react-router";


export default class Footer extends Component {

  constructor() {
    super();
    this.state = {
      index: null
    }
  }

  componentDidMount() {
    if (!localStorage.getItem("token")) {
      let pathname = this.props.pathname;
      if (pathname) {
        hashHistory.push("/login?redirect=" + pathname);
      } else {
        hashHistory.push("/login");
      }
    }
    this.setState({
      index: this.props.index
    })
  }

  hanldeChange(index) {
    if (0 === index) {
      hashHistory.push("/");
    } else if (1 === index) {
      hashHistory.push("/control");
    } else if (2 === index) {
      hashHistory.push("/recover");
    } else if (3 === index) {
      hashHistory.push("/setting");
    } else if (4 === index) {
      hashHistory.push("/table");
    }
    this.setState({
      index: index
    })
  }

  render() {
    return (
      <div className="footer">
        <Tabbar fixed={true} value={this.state.index} onChange={this.hanldeChange.bind(this)}>
          <Tabbar.Item icon={<WapHomeO />}>首页</Tabbar.Item>
          <Tabbar.Item icon={<DesktopO />}>控制</Tabbar.Item>
          <Tabbar.Item icon={<UnderwayO />}>恢复</Tabbar.Item>
          <Tabbar.Item icon={<SettingO />}>设置</Tabbar.Item>
          <Tabbar.Item icon={<BarChartO />}>报表</Tabbar.Item>
        </Tabbar>
        <NumberKeyboard safeAreaInsetBottom />
      </div>
    );
  }
}
