import {Component} from 'react';
import { Form, Field, Cell, Button, NavBar, Checkbox, Toast } from 'react-vant';
import axios from 'axios';
import querystring from 'querystring';
import constant from './constant';
import aes from './aes';
import './base.css';
import './login.css';
import { hashHistory } from "react-router";

export default class Login extends Component {

  constructor() {
    super();
    this.state = {
      name: "",
      username: "",
      password: "",
      remember: false,
      redirect: false
    }
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      hashHistory.push('/');
    }
    if (localStorage.getItem('username')) {
      this.setState({
        username: localStorage.getItem('username'),
        password: aes.decrypte(localStorage.getItem('password')),
        remember: true
      })
    }
    axios.post(constant.host + "api/v1/user/info").then((res) => {
      if (res.data.base_resp.ret === 0) {
        this.setState({
          name: res.data.data
        });
      }
    });
  }

  handleChange(username, value) {
    this.setState({
      [username]: value
    });
  }

  handleSubmit() {
    let username = this.state.username;
    let password = aes.encrypte(this.state.password);
    axios.post(
      constant.host + "api/v1/login",
      querystring.stringify({
        username: username,
        password: password
      }), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    ).then((res) => {
      if (res.data.base_resp.ret === 0) {
        if (this.state.remember) {
          localStorage.setItem('username', username);
          localStorage.setItem('password', password);
          localStorage.setItem('token', res.data.data);
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("password");
          localStorage.setItem('token', res.data.data);
        }
        let redirect = this.props.location.query.redirect;
        if (redirect) {
          hashHistory.push("/" + redirect);
        } else {
          hashHistory.push('/');
        }
      } else if (res.data.base_resp.ret === -3) {
        Toast.info('重启此软件的电脑后登录');
      } else {
        Toast.info('账号或密码错误');
        localStorage.removeItem("token");
      }
    });
  }


  render() {
    return (
      <div className="login">
        <NavBar title={this.state.name} leftArrow={false}/>
        <Form className="user">
          <Cell.Group border={false}>
            <Field className="input" label="用户名" placeholder="用户名" value={this.state.username} onChange={this.handleChange.bind(this, "username")} />
            <Field className="input" type="password" label="密码" placeholder="密码" value={this.state.password} onChange={this.handleChange.bind(this, "password")} />
          </Cell.Group>
          <Checkbox checked={this.state.remember} className="checkbox" onChange={this.handleChange.bind(this, "remember")}>记住密码</Checkbox>
          <div>
            <Button className="button" type="primary" round={true} block={true} onClick={this.handleSubmit.bind(this)} >
              登录
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}
