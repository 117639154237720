import {Component} from 'react';
import { Form, Field, Button, NavBar, Cell, Tabs, Calendar, Dialog, Toast, Popup, Picker } from 'react-vant';
import querystring from 'querystring';
import { hashHistory } from "react-router";
import './base.css';
import './clear.css';
import requests from './requests';


export default class Clear extends Component {

  constructor() {
    super();
    this.state = {
      visible: false,
      dates: [],
      hallsData: [],
      halls: [],
      moviesData: [],
      movies: [],
      id: '',
      hall: '',
      code: '',
      movie: '',
      ticket: ''
    }
  }

  componentDidMount() {
    let data = querystring.stringify();
    requests.post(data, "api/v1/findHall").then((res) => {
      if(res.data.base_resp.ret === 0) {
        let halls = res.data.data;
        let data = [];
        halls.forEach(function (item) {
          data.push(item["CIN_REMARKS"]);
        });
        this.setState({
          hallsData: halls,
          halls: data,
        })
      }
    });
  }

  handleClearSubmit() {
    let dates = this.state.dates;
    let ticket = this.state.ticket;
    if (dates.length > 0 || ticket) {
      Dialog.confirm({
        message:
          '将要清除选择的数据，不可恢复，确定清除吗？',
      }).then(() => {
        let data;
        if (ticket) {
          data = querystring.stringify({dates: '', hallId: '', sessionCode: '', ticketCode: ticket});
        } else {
          let hallId =this.state.id;
          let sessionCode = this.state.code;
          data = querystring.stringify({dates: dates.join(','), hallId: hallId, sessionCode: sessionCode, ticketCode: ticket});
        }
        requests.post(data, "api/v1/deleteDaySettleTicket").then((res) => {
          if(res.data.base_resp.ret === 0) {
            Toast.success('清除完成');
          }
        });
      }).catch(() => {
      });
    }
  }

  handleClearAllSubmit() {
    Dialog.confirm({
      message:
        '将要清除所有日结过的数据，不可恢复，确定清除吗？',
    }).then(() => {
      let data = querystring.stringify();
      requests.post(data, "api/v1/deleteAllDaySettleTicket").then((res) => {
        if(res.data.base_resp.ret === 0) {
          Toast.success('清除完成');
        }
      });
    }).catch(() => {
    });
  }

  getDate(date) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  handleConfirmMulti(dates) {
    let _this = this;
    let data = [];
    dates.forEach(function (date) {
      data.push(_this.getDate(date));
    });
    this.setState({
      visible: false,
      dates: data,
      id: '',
      hall: '',
      code: '',
      movie: '',
      ticket: ''

    })
  }

  handleClick() {
    this.setState({
      visible: !this.state.visible
    })
  }

  handleChooseClick(item) {
    if (item === "showHalls") {
      if (this.state.halls.length > 0) {
        if (this.state.dates.length === 1) {
          this.setState({
            [item]: true
          });
        } else if (this.state.dates.length > 0) {
          Toast.info('只能选择一个日期');
        } else {
          Toast.info('选择一个日期');
        }
      } else {
        Toast.info('没有选择的影厅');
      }
    } else if (item === "showMovies") {
      if (this.state.movies.length > 0) {
        if (this.state.dates.length === 1) {
          if (this.state.hall) {
            this.setState({
              [item]: true
            });
          } else {
            Toast.info('选择一个影厅');
          }
        } else if (this.state.dates.length > 0) {
          Toast.info('只能选择一个日期');
        } else {
          Toast.info('选择一个日期');
        }
      } else {
        Toast.info('没有选择的场次');
      }
    } else {
      this.setState({
        [item]: true
      });
    }
  }

  handleHallCancle() {
    this.setState({
      showHalls: false
    });
  }

  handleHallConfirm(value) {
    let id = '';
    this.state.hallsData.forEach(function (item) {
      if (item["CIN_REMARKS"] === value) {
        id = item["CIN_CINEMA_CD"];
      }
    });
    this.setState({
      id: id,
      hall: value,
      code: '',
      movie: '',
      showHalls: false
    });
    let data = querystring.stringify({id: id, date: this.state.dates[0]});
    requests.post(data, "api/v1/findPlanByHall").then((res) => {
      if(res.data.base_resp.ret === 0) {
        let movies = res.data.data;
        let data = [];
        movies.forEach(function (item) {
          data.push(item["FILMNAME"] + item["SHOWSTARTTIME"].split(" ")[1]);
        });
        this.setState({
          moviesData: movies,
          movies: data
        })
      }
    });
  }

  handleMovieCancle() {
    this.setState({
      showMovies: false
    });
  }

  handleMovieConfirm(value) {
    let code = '';
    this.state.moviesData.forEach(function (item) {
      if (item["FILMNAME"] + item["SHOWSTARTTIME"].split(" ")[1] === value) {
        code = item["SESSIONCODE"];
      }
    });
    this.setState({
      code: code,
      movie: value,
      showMovies: false
    });
  }

  handleTicketChange(value) {
    this.setState({
      ticket: value
    })
  }


  render() {
    return (
      <div className="clear">
        <NavBar title="数据清除" border={false} onClickLeft={hashHistory.goBack}/>
        <Tabs active={0}>
          <Tabs.TabPane key={0} title="部分清除">
            <div className="tabs">
              <div className="before">
                <Form>
                  <Cell.Group border={false}>
                    <Cell title="选择日期" value={this.state.dates.length > 0 && "选择了" + this.state.dates.length + "个日期"} isLink={true} onClick={this.handleClick.bind(this)} />
                    <Cell title="选择影厅" value={this.state.hall} isLink={true} onClick={this.handleChooseClick.bind(this, "showHalls")} />
                    <Cell title="选择场次" value={this.state.movie} isLink={true} onClick={this.handleChooseClick.bind(this, "showMovies")} />
                    <Field className="input" label="单票清除" placeholder="请输入票号" onChange={this.handleTicketChange.bind(this)} value={this.state.ticket} />
                    <Calendar
                      minDate={new Date(2020, 12, 1)}
                      maxDate={new Date(2030, 12, 1)}
                      defaultValue={new Date()}
                      style={{ height: 500 }}
                      visible={this.state.visible}
                      type={"multiple"}
                      poppable={true}
                      onClose={this.handleClick.bind(this)}
                      onConfirm={(dates) => {
                        this.handleConfirmMulti(dates);
                      }}
                    />
                  </Cell.Group>
                  <div>
                    <Button className="button" type="primary" round={true} block={true} onClick={this.handleClearSubmit.bind(this)} >
                      确定
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane key={1} title="全部清除">
            <div className="tabs">
              <div className="after">
                <Button className="button" type="danger" round={true} block={true} onClick={this.handleClearAllSubmit.bind(this)} >
                  确定
                </Button>
              </div>
            </div>
          </Tabs.TabPane>
        </Tabs>
        <Popup visible={this.state.showHalls} position="bottom" round={true} title="选择影厅">
          <Picker columns={this.state.halls} onCancel={this.handleHallCancle.bind(this)} onConfirm={this.handleHallConfirm.bind(this)}>
          </Picker>
        </Popup>
        <Popup visible={this.state.showMovies} position="bottom" round={true} title="选择场次">
          <Picker columns={this.state.movies} onCancel={this.handleMovieCancle.bind(this)} onConfirm={this.handleMovieConfirm.bind(this)}>
          </Picker>
        </Popup>
      </div>
    );
  }
}
